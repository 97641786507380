export const addToCart =
  ({ dispatch }: { dispatch: any }) =>
  (contentIds: string[], currencyCode: string, value: number) => {
    const decodedIds = contentIds.map((gid: string) => {
      return gid.split('/').slice().pop()
    })

    return Promise.all([
      dispatch({
        type: 'EVENT',
        payload: {
          type: 'AddToCart',
          content: {
            content_ids: decodedIds,
            content_type: 'product',
            currency: currencyCode,
            value: value,
          },
        },
      }),
      fetch(`${window.location.origin}/api/conversion/AddToCart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contentIds: decodedIds,
          currency: currencyCode,
          value: value,
        }),
      }),
    ])
  }

export const viewContent =
  ({ dispatch }: { dispatch: any }) =>
  (contentType: string, contentIds: string[]) => {
    const decodedIds = contentIds.map((gid: string) => {
      return gid.split('/').slice().pop()
    })

    return Promise.all([
      dispatch({
        type: 'EVENT',
        payload: {
          type: 'ViewContent',
          content: {
            url: window.location.href,
            content_type: contentType,
            content_ids: decodedIds,
          },
        },
      }),
      fetch(`${window.location.origin}/api/conversion/ViewContent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: window.location.href,
          contentType: contentType,
          contentIds: decodedIds,
        }),
      }),
    ])
  }

export const initiateCheckout = ({ dispatch }: { dispatch: any }) => {
  return Promise.all([
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'InitiateCheckout',
      },
    }),
    fetch(`${window.location.origin}/api/conversion/InitiateCheckout`, {
      method: 'POST',
    }),
  ])
}

export const lead = ({ dispatch }: { dispatch: any }) => {
  return Promise.all([
    dispatch({
      type: 'EVENT',
      payload: {
        type: 'Lead',
      },
    }),
    fetch(`${window.location.origin}/api/conversion/Lead`, {
      method: 'POST',
    }),
  ])
}